<template>
  <div class="assessment-plans">
    <app-server-data-table
      ref="table"
      :headers="cols"
      :items-provider="getPlansList"
      v-model:params="params"
      v-model:selected-items="selectedPlans"
      checkable
      colsable
      sortable
      searchable
      filterable
      with-pagination
      save-on-url
    >
      <template #header-actions>
        <mt-button
          size="small"
          @click="createPlan"
          class="assessment-plans__add"
        >
          <plus-icon class="assessment-plans__add__icon" />
          Добавить план
        </mt-button>
      </template>

      <template #name="{ item }">
        {{ item.name }}

        <div class="assessment-plans__chips">
          <app-chip
            v-for="tag in item.tags"
            :key="tag"
            class="assessment-plans__chip"
          >
            {{ tag }}
          </app-chip>
        </div>
      </template>
      <template #datetime="{ item }">
        <template v-if="!item.date_from && !item.date_to">
          не указано
        </template>
        <span v-if="!!item.date_from">
          {{ `c ${formatDateTimeOfItemByDefault(item.date_from, item)}` }} </span
        >&nbsp;
        <span v-if="!!item.date_to">
          {{ `по ${formatDateTimeOfItemByDefault(item.date_to, item)}` }}
        </span>
      </template>
      <template #active="{ item, index }">
        <mt-switch
          hide-details
          theme="solid"
          v-model="item.active"
          @update:modelValue="onChangePlanStatus(item)"
        />
      </template>
      <template #participants="{ item }">
        <app-tooltip bottom>
          <template #activator>
            <mt-chip
              bg-color="light-green"
              :to="{
                name: 'AddEdit4',
                params: {
                  planUuid: item.uuid,
                },
                query: {
                  planName: item.name,
                },
              }"
            >
              {{ item.respondents }} / {{ item.experts }}
            </mt-chip>
          </template>
          <span style="white-space: nowrap">Респонденты / Эксперты</span>
        </app-tooltip>
      </template>
      <template #progress="{ item }">
        <app-tooltip
          bottom
          dense
          class="assessment-plans__table__progress-wrapper"
        >
          <template #activator>
            <router-link
              class="assessment-plans__table__progress"
              :to="{
                name: 'AssessmentPlanSingleResults',
                params: {
                  planUuid: item.uuid,
                },
              }"
            >
              <div class="assessment-plans__table__progress__title">
                {{ item.progress.current_percent }}%
                <chevron-right-icon
                  class="assessment-plans__table__progress__arrow"
                />
              </div>
              <mt-linear-progress
                class="assessment-plans__table__progress__bar"
                background-color="#DFE0EB"
                :height="6"
                :progress="item.progress.current_percent"
              />
            </router-link>
          </template>
          Результаты
        </app-tooltip>
      </template>
      <template #branch="{ item }">
        {{ item.branch?.name }}
      </template>
      <template #actions="{ item }">
        <div class="assessment-plans__item-actions">
          <mt-button
            icon
            class="assessment-plans__item-action"
            :to="{
              name: 'AddEdit1',
              params: {
                planUuid: item.uuid,
              },
            }"
          >
            <pencil-icon />
          </mt-button>
          <mt-button
            icon
            class="assessment-plans__item-action"
            :to="{
              name: 'PlanSettings',
              params: {
                uuid: item.uuid,
              },
              query: {
                planName: item.name,
              },
            }"
          >
            <settings-icon />
          </mt-button>
          <mt-button
            icon
            color="light-red"
            class="assessment-plans__item-action"
            @click.stop="activateDeletePlanDialog(item)"
          >
            <trash-icon />
          </mt-button>
        </div>
      </template>

      <template #footer-actions>
        <mt-button
          class="app-data-table__footer__actions__item"
          :disabled="!selectedPlans.length"
          @click="onAddTagClicked"
        >
          Присвоить тэг
        </mt-button>
        <mt-button
          class="app-data-table__footer__actions__item"
          :disabled="!selectedPlans.length"
          @click="onSelectChangePlanStatus"
        >
          Вкл/выкл
        </mt-button>
        <mt-button
          class="app-data-table__footer__actions__item"
          :disabled="!selectedPlans.length || selectedPlans.length>1"
          @click="activateReplicatePlansDialog"
        >
          Копировать
        </mt-button>
        <mt-button
          class="app-data-table__footer__actions__item"
          :disabled="!selectedPlans.length"
          @click="activateDeletePlansDialog()"
        >
          Удалить
        </mt-button>

        <mt-button
          class="app-data-table__footer__actions__item"
          v-if="$route.name === 'AssessmentPlansArchive'"
          :disabled="!selectedPlans.length"
          @click="activateArchiveDialog()"
        >
          Восстановить
        </mt-button>
        <mt-button
          v-else
          :disabled="!selectedPlans.length"
          @click="activateArchiveDialog()"
          class="app-data-table__footer__actions__item"
        >
          Архивировать
        </mt-button>
      </template>
    </app-server-data-table>

    <teleport to="body">
      <mt-tags-popover
        v-model:visibility="showTagsPopup"
        :addLoader="addNewTagLoader"
        :tags="tags"
        :foundTags="foundTags"
        @close="closeTagsPopover"
        @search="searchTags"
        @select="selectTag"
        @add="addTag"
        @delete="deleteTag"
      />

      <confirmation-dialog
        v-model="deletePlanDialogActivator"
        :title="`Удалить план ${plan?.name}?`"
        text="Все данные, связанные с этим планом оценки, будут удалены. Если вы хотите сохранить данные о плане, рекомендуем его заархивировать"
        :handler="deletePlan"
        submit-text="Да, удалить"
      />

      <confirmation-dialog
        v-model="deletePlansDialogActivator"
        :title="`Удалить планы? Количество: ${this.selectedPlans?.length}`"
        :handler="deleteSelectedPlans"
      />

      <confirmation-dialog
        v-model="archiveDialogActivator"
        :title="`${
          $route.name === 'AssessmentPlansArchive'
            ? 'Восстановить планы?'
            : 'Переместить планы в архив?'
        } Количество: ${this.selectedPlans?.length}`"
        :handler="
          $route.name === 'AssessmentPlansArchive'
            ? removeFromArchive
            : onAddToArchive
        "
      />

      <mt-dialog
        size="md"
        v-model="replicatePlansDialogActivator"
        class="replicate-plan-modal"
      >
        <h3 class="replicate-plan-modal__title">Копирование плана</h3>

        <div class="replicate-plan-modal__inputs">
          <label>Опции копирования:</label>

          <app-checkbox v-model="replicate.structure">
            <template #label>
                  <span>
                    Структура (задания, шкалы, интерпретации)
                  </span>
            </template>
          </app-checkbox>

          <app-checkbox v-model="replicate.participants">
            <template #label>
                  <span>
                    Участники (респонденты, эксперты)
                  </span>
            </template>
          </app-checkbox>
        </div>

        <mt-button class="w-100" @click="replicateSelectedPlans">
          Копировать
        </mt-button>
      </mt-dialog>
    </teleport>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import mtTable from '@/components/UI/mtTable/mtTable.vue'
import mtIcon from '@/components/UI/mtIcon/mtIcon.vue'
import mtPagination from '@/components/UI/mtPagination/mtPagination.vue'
import mtFilters from '@/components/UI/mtFilters/mtFilters.vue'
import mtSwitch from '@/components/UI/mtSwitch/index.vue'
import mtPreloader from '@/components/UI/mtPreloader/mtPreloader.vue'
import mtLinearProgress from '@/components/UI/mtLinearProgress/mtLinearProgress.vue'
import mtTagsPopover from '@/components/composite/mtTagsPopover.vue'
import mtCheckbox from '@/components/UI/mtCheckbox/index.vue'
import ConfirmationDialog from '@/components/composite/confirmationDialog/confirmationDialog.vue'
import MtSvg from '@/components/UI/mtSvg/index.vue'
import AppChip from '@/components/UI/AppChips/AppChip.vue'
import MtChip from '@/components/UI/mtChip/index.vue'
import ListIcon from '@/components/UI/mtSvg/svg/ListIcon.vue'
import AppTooltip from '@/components/UI/AppTooltip/AppTooltip.vue'
import TableCheckIcon from '@/components/UI/mtSvg/svg/TableCheckIcon.vue'
import InfoBlock from '@/components/workspace/InfoBlock.vue'
import PencilIcon from '@/components/icons/PencilIcon.vue'
import MtButton from '@/components/UI/mtButton/index.vue'
import SettingsIcon from '@/components/icons/SettingsIcon.vue'
import TrashIcon from '@/components/icons/TrashIcon.vue'
import ChevronRightIcon from '@/components/icons/ChevronRightIcon.vue'
import {  formatDateTimeOfItemByDefault } from "@/services/date.js";
import AppServerDataTable from '@/components/UI/AppServerDataTable/AppServerDataTable.vue'
import { deepClone } from '@/services/utils.js'
import PlansAdminApi from '@/api/plans-admin.api'
import PlusIcon from '@/components/icons/PlusIcon.vue'
import DictionaryApi from '@/api/dictionary.api'
import AppInput from "@/components/UI/AppInput/AppInput.vue";
import AppTextarea from "@/components/UI/AppTextarea/AppTextarea.vue";
import MtDialog from "@/components/UI/mtDialog/mtDialog.vue";
import AppCheckbox from "@/components/UI/AppCheckbox/AppCheckbox.vue";

export default {
  components: {
    AppCheckbox,
    MtDialog, AppTextarea, AppInput,
    PlusIcon,
    AppServerDataTable,
    ChevronRightIcon,
    TrashIcon,
    SettingsIcon,
    MtButton,
    PencilIcon,
    InfoBlock,
    TableCheckIcon,
    AppTooltip,
    ListIcon,
    MtChip,
    AppChip,
    MtSvg,
    ConfirmationDialog,
    mtIcon,
    mtTable,
    mtPagination,
    mtFilters,
    mtSwitch,
    mtCheckbox,
    mtPreloader,
    mtLinearProgress,
    mtTagsPopover,
  },
  data() {
    return {
      showTagsPopup: false,
      cols: [
        {
          title: 'Название плана',
          stub: 'name',
          visible: true,
          filter: 'string',
          filterable: true,
          sortable: true,
          showable: false,
        },
        {
          title: 'Период доступа',
          stub: 'datetime',
          visible: true,
          filterable: false,
          sortable: false,
          showable: true,
        },
        {
          title: 'Включить',
          stub: 'active',
          visible: true,
          filter: 'list',
          filterable: true,
          sortable: true,
          showable: true,
        },
        {
          title: 'Участники',
          stub: 'participants',
          visible: true,
          filter: 'range',
          filterable: true,
          sortable: true,
          showable: true,
        },
        {
          title: 'Прогресс',
          stub: 'progress',
          visible: true,
          filter: 'range',
          filterable: false,
          sortable: false,
          showable: true,
        },
        {
          title: 'Тесты',
          stub: 'MaintestName',
          visible: false,
          filter: 'list',
          filterable: true,
          sortable: false,
          showable: false,
        },
      ],
      searchAddTag: '',
      params: {},
      selectedPlans: [],

      addNewTagLoader: false,
      tags: [],
      foundTags: [],
      plan: null,
      deletePlanDialogActivator: false,
      deletePlansDialogActivator: false,
      replicatePlansDialogActivator: false,
      archiveDialogActivator: false,
      isLoading: false,
      replicate: {
        structure:true,
        participants:false,
      },
    }
  },
  computed: {
    ...mapGetters(['authUserRoleSlug']),
  },
  methods: {
    formatDateTimeOfItemByDefault,
    ...mapActions('assessmentPlans/plan', [
      /* LIST */
      'fetchPlans',
      'resetPlans',
      'addTags',
      'deletePlans',
      'replicatePlans',
      'switchPlans',
      'addToArchive',
      'restorePlan',
      'resetPlans',

      /* ITEM */
      'deletePlan',
      'updatePlan',
    ]),

    /* GETTERS */
    async getPlansList() {
      let params = deepClone(this.params)

      const archiveFilter = {
        field: 'archive',
        title: 'Архив',
        type: 'list',
        value: [
          this.authUserRoleSlug === 'admin' &&
            this.$route.name === 'AssessmentPlansArchive',
        ],
      }

      if (params?.settings?.filters) {
        params.settings.filters.push(archiveFilter)
      } else {
        params.settings.filters = [archiveFilter]
      }

      return await this.fetchPlans(params)
    },

    createPlan() {
      PlansAdminApi.create().then((response) => {
        this.$router.push({
          name: 'AddEdit1',
          params: {
            planUuid: response.data.uuid,
          },
        })
      })
    },

    activateDeletePlanDialog(item) {
      this.plan = item
      this.deletePlanDialogActivator = true
    },
    async deletePlan() {
      return await PlansAdminApi.delete(this.plan.uuid).then(() => {
        this.$refs.table.getItems()
      })
    },
    activateDeletePlansDialog() {
      this.deletePlansDialogActivator = true
    },
    activateReplicatePlansDialog() {
      this.replicatePlansDialogActivator = true
    },
    async deleteSelectedPlans() {
      if (!this.selectedPlans.length) return

      return await this.deletePlans({
        data: this.selectedPlans.map((plan) => plan.uuid),
      }).then(() => {
        this.$refs.table.getItems()
      })
    },
    async replicateSelectedPlans() {
      if (!this.selectedPlans.length) return

      return await this.replicatePlans({
        data: {
          uuid: this.selectedPlans.map((plan) => plan.uuid),
          options: this.replicate
        }
      }).then(() => {
        this.$refs.table.getItems()
        this.replicatePlansDialogActivator=false;
      })
    },

    activateArchiveDialog() {
      this.archiveDialogActivator = true
    },

    async onAddToArchive() {
      if (!this.selectedPlans.length) return

      await this.addToArchive({
        body: this.selectedPlans.map((plan) => plan.uuid),
      })
      await this.$refs.table.getItems()
      this.selectedPlans = []
    },
    async removeFromArchive() {
      if (!this.selectedPlans.length) return

      await this.restorePlan({
        body: this.selectedPlans.map((plan) => plan.uuid),
      })
      await this.$refs.table.getItems()
      this.selectedPlans = []
    },

    closeTagsPopover() {
      this.showTagsPopup = false
    },
    async searchTags({ tag }) {
      if (tag) {
        this.foundTags = await DictionaryApi.getTags({ search: tag }).then(
          (response) => response.data.data,
        )
      } else {
        this.foundTags = []
      }
    },
    selectTag({ tag }) {
      this.searchTag = tag
      this.foundTags = []
    },
    async addTag({ tag }) {
      if (!this.addNewTagLoader) {
        this.addNewTagLoader = true

        if (!!tag.replaceAll(' ', '')) {
          const tags = this.selectedPlans.map((plan) => {
            return {
              uuid: plan.uuid,
              tags: [...new Set([tag, ...plan.tags.map((tag) => tag)])],
            }
          })

          await this.addTags({ tags })
          await this.selectedPlans.forEach((selectedPlan) => {
            selectedPlan.tags = [...new Set([tag, ...selectedPlan.tags])]
          })
          await this.parseTags()
        }

        this.addNewTagLoader = false
      }
    },
    async deleteTag({ tag }) {
      if (!!tag.replaceAll(' ', '')) {
        const tags = this.selectedPlans.map((plan) => {
          return {
            uuid: plan.uuid,
            tags: plan.tags.filter((_tag) => _tag !== tag),
          }
        })

        await this.addTags({ tags })
        await this.selectedPlans.forEach((selectedPlan) => {
          selectedPlan.tags = selectedPlan.tags.filter((_tag) => _tag !== tag)
        })
        await this.parseTags()
      }
    },
    async onAddTagClicked() {
      this.foundTags = []

      if (this.selectedPlans.length) {
        this.showTagsPopup = !this.showTagsPopup

        if (this.showTagsPopup) {
          await this.parseTags()
        }
      }
    },

    onChangePlanStatus(plan) {
      this.switchPlans({
        plans: [plan],
        active: plan.active,
      })
    },

    onSelectChangePlanStatus() {
      const payload = {
        plans: this.selectedPlans,
        active: !this.selectedPlans.filter((plan) => plan.active).length,
      }

      this.selectedPlans.forEach((plan) => {
        plan.active = payload.active
      })

      this.switchPlans(payload)
    },
    openSettings({ plan }) {
      this.$router.push({
        name: 'PlanSettings',
        params: {
          uuid: plan.uuid,
        },
        query: {
          planName: plan.name,
        },
      })
    },

    updatePlansList() {
      this.resetPlans()
      this.$refs.table.getItems()
    },
    async parseTags() {
      let tags = []

      await this.selectedPlans.forEach((plan) => {
        tags.push(...plan.tags)
      })

      this.tags = [...new Set(tags)].map((tag) => ({
        name: tag,
      }))
    },
  },
}
</script>

<style lang="scss">
.assessment-plans {
  &__title {
    font-weight: bold;
    font-size: 19px;
    line-height: 24px;
    letter-spacing: 0.4px;
    color: #252733;
    padding-bottom: 10px;
  }

  &__add {
    &__icon {
      width: 16px;
      height: 16px;
      margin-right: 4px;
    }
  }

  &__table {
    &__name {
      max-width: 200px;
      min-width: 100px;
    }
    &__progress {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      &:hover {
        .assessment-plans__table__progress__title {
          color: $black;
        }
        .assessment-plans__table__progress__arrow {
          transform: translateX(3px);
        }
      }
      &-wrapper {
        width: 100%;
      }

      &__arrow {
        width: 16px;
        transition: transform 0.15s;
      }

      &__title {
        font-weight: 500;
        color: $dark;
        font-size: 13px;
        display: flex;
        align-items: center;
      }

      &__bar {
        width: 100%;
        margin-top: 6px;
      }
    }
  }

  &__header {
    &-cell {
      padding: 12px 30px;
      vertical-align: middle;
      text-align: center;

      &_first {
        text-align: left;
      }

      span {
        white-space: nowrap;
        display: flex;
        align-items: center;
      }

      &__sort-icon {
        margin-left: 5px;
        cursor: pointer;
        width: 16px;
        height: 16px;
        transition: transform 0.3s;
        color: $green;
        flex-shrink: 0;
      }
    }
  }

  &__body {
    &-tags {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
    }

    &-tag {
      margin-right: 5px;
    }
  }

  &__item {
    display: table-row;
    align-items: center;
    flex-grow: 1;
    padding: 0 30px;
    min-height: 90px;
    border-bottom: solid #dfe0eb 1px;
    color: #252733;

    &:last-child {
      border-bottom: 0;
    }

    &:hover:not(.assessment-plans__item_selected) {
      background-color: #f7f8ff;
    }

    &_selected {
      background: #e7e9ff;
    }

    &-result-btn {
      margin: 0 24px;
      width: 24px;
      height: 24px;
      cursor: pointer;
      color: $gray;
    }

    &-actions {
      display: flex;
    }

    &-action {
      margin-right: 12px;

      &:last-child {
        margin-right: 0px;
      }
    }
  }

  &__add-tag-btn {
    width: 45px;
    height: 45px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    background: #00788c;
    border-radius: 4px;
    text-align: center;
    cursor: pointer;
  }

  &__chips {
    margin-top: 2px;
    display: flex;
    flex-wrap: wrap;
  }

  &__chip {
    padding: 2px 5px;
    font-size: 12px;
    border-width: 1px;
    font-weight: 500;
    text-transform: unset;
    color: #9fa2b4;
    border-color: #9fa2b4;
    background-color: transparent;
    margin-top: 3px;
    margin-right: 3px;
  }
}

/* MIXINS STYLES CSS START */
.preloader_margin {
  margin: 10px 10px 0px 10px;
}

.participants__text {
  cursor: pointer;
}

.participants {
  &__text {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: #0073e5;
  }
}

.replicate-plan-modal{
  &__title{
    font-size: 24px;
    margin-bottom: 20px;
    text-align: center;
    font-weight: bold;
  }

  &__inputs {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 30px;
    gap: 10px;

    label {
      margin-right: 4px;
    }
  }

  .app-form-block{
    margin-bottom: 0;
  }
}

/* MIXINS STYLES CSS END */
</style>
